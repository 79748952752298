

*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  text-rendering: optimizeLegibility;
}

.nav {
    position: sticky;
    top: 0;
    z-index: 10;
    transition: 0.8s all ease;
}

.nav_container {
    z-index: 1;
    width: 100%;
}

.active {
    color: #1c1c1c;
    font-weight: 500;
    background-color: #f5f5f5;
    padding: 5px 20px 5px 20px;
    border-radius: 4px;
}

.scroll {
    position: fixed;
    cursor: pointer;
    bottom: 12px;
    left: 90%;
    width: 70px;
    height: 70px;
    /* background: #eaeaea; */
    border-radius: 50px;
    /* color: #2d2d2d; */
    z-index: 1;
}

@media screen and (max-width: 768px) {
    .scroll {
        left: 85%;
    }
}

.icon {
    width: 30px;
    height: 30px;
    /* animation: scrollToTheTop 0.5s alternate ease infinite;  */
}

.scroll__down {
    animation: scrollDown 0.5s alternate ease infinite;  
}

@keyframes scrollDown {
    from {
        transform: translateY(2px);
    }
    to {
        transform: translateY(-1px);
    }
}
@keyframes scrollToTheTop {
    from {
        transform: translateY(2px);
    }
    to {
        transform: translateY(-1px);
    }
}

:root {
    --animate-delay: 0.5s;
}

.prototype {
    width: 1000px;
}


